<template>
	<v-navigation-drawer class="drawer background" v-model="drawer" width="100%" app temporary>
		<v-icon right class="float-right" @click="setDrawer(false)">mdi-close</v-icon>
		<v-list>
			<v-list-item v-for="item in links" :key="item.text" :to="{ name: item.link }" link exact>
				<v-icon left>{{ item.icon }}</v-icon>
				<v-list-item-title v-text="item.text" />
			</v-list-item>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
	name: "CoreDrawer",

	computed: {
		...mapGetters(["links"]),
		drawer: {
			get() {
				return this.$store.state.drawer;
			},
			set(val) {
				this.setDrawer(val);
			},
		},
	},

	methods: {
		...mapMutations(["setDrawer"]),
	},
};
</script>
<style lang="scss" scoped>
.v-list-item--link {
	&::before {
		background-color: rgb(23, 157, 219);
	}
}
.v-list-item--active {
	color: rgb(6, 42, 160);
}
.drawer {
	padding: 15px 15px 15px !important;
	border-radius: 0 10px 0 10px;
	background: url("../../assets/book.png") no-repeat center;
	background-color: rgb(19, 105, 139);
	background-size: contain;
	font-family: "GloriaHallelujah" !important;
	font-style: italic !important;
	font-weight: bold;

	&.background {
		background: rgb(246, 249, 254); /* Old browsers */
		background: -moz-linear-gradient(top, rgba(246, 249, 254, 1) 5%, rgba(226, 240, 254, 1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(246, 249, 254, 1) 5%, rgba(226, 240, 254, 1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(246, 249, 254, 1) 5%, rgba(226, 240, 254, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f9fe', endColorstr='#e2f0fe',GradientType=0 ); /* IE6-9 */
	}
}
</style>
